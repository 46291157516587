<template>
  <v-row>
    <v-dialog
        v-model="isShowFlg"
        fullscreen
    >
      <div class="dialog_style">
        <slick ref="slick" :options="slickOptions">
          <div class="pt-15">
            <div class="text">
              <p class="header my-1">会員登録して</p>
              <p class="header my-1">ポイントを貯めよう！</p>
            </div>
            <img class="photo" src="@/assets/tutorial1.png" width="80%"/>
            <div class="text">
              <span>お買い物でポイントが貯まる！</span><br />
              <span>貯まったポイントはお店でも</span><br />
              <span>オンラインショップでもご利用可能！</span>
            </div>
            <div class="btn" @click="next()">次へ</div>
          </div>
          <div class="pt-15">
            <div class="text">
              <p class="header my-1">＼ Wチャンス ／</p>
              <p class="header_sm my-1">来店でスタンプが貯まる！</p>
            </div>
            <img class="photo" src="@/assets/tutorial2.png" width="80%"/>
            <div class="text">
              <span>来店購入時に貯まるスタンプ10個で</span><br />
              <span>やまや商品をプレゼント♪</span>
            </div>
            <div class="btn" @click="next()">次へ</div>
            <div class="btn button-prev" @click="prev()">戻る</div>
          </div>
          <div class="pt-15">
            <div class="text">
              <p class="header my-1">お店で使える</p>
              <p class="header my-1">お得なクーポン！</p>
            </div>
            <img class="photo" src="@/assets/tutorial3.png" width="80%"/>
            <div class="text">
              <span>アプリ限定のクーポンを配信！</span><br />
              <span>よりお得にお楽しみください。</span>
            </div>
            <div>
              <div class="btn" @click="startLoginIndex()">はじめる</div>
              <div class="btn button-prev" @click="prev()">戻る</div>
              <div style="height: 30px;" />
            </div>
          </div>
        </slick>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import Slick from "vue-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
export default {
  components: {
    Slick,
  },
  data() {
    return {
      slickOptions: {
        infinite: false,
        autoplay: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '0px',
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
      },
      isShowFlg: true,
      isCloseDialog: 0,
    }
  },
  created(){
  },
  methods: {
    // iOS、AndroidともにNative側で下記URLをフック。URLはそれっぽくしましたが何でもよくてただの文字列と思ってください。
    // Native側はactionでの処理の振り分けは特に行っていません。
    // アプリ起動時に本チュートリアルを表示して、表示したことをNativeの記憶領域(UserDefaults/SharedPreferences)に保存し、2回目以降は表示しません。
    closeTutorial(action) {
      window.location.href = 'callback://native/' + action
    },
    startLoginIndex() {
      this.$router.push({ name: 'index', query: {}})
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    }
  }
}
</script>
<style scoped>

.header {
  font-size:27px;
  font-weight:bold;
  color:#E90606
}

.header_sm {
  font-size:25px;
  font-weight:bold;
  color:#E90606
}

.dialog_style {
  background-color: white;
  height: 100%;
  z-index: 99 !important
}
.dialog_style .slide {
  display:flex;
  flex-flow:column wrap;
  align-items: center;
  justify-content:center;
}
.dialog_style .photo {
  margin: 1rem auto 1rem auto;
  padding-top: 0%;
}
.dialog_style .text {
  padding-top: 0rem;
  height: 7rem;
  text-align: center;
}
.dialog_style .btn {
  text-align: center;
  position: relative!important;
  margin:0 auto 1.25rem auto!important;
  width:15rem; height: auto;
  right: auto; left:auto;
  top: auto;
  padding:0.375rem;
  line-height:1.5;
  background:#E2333E;
  border:2px solid #E2333E;
  color:#fff !important;
  box-shadow:none;
  z-index:0;
  box-sizing:border-box;
  border-radius:2rem;
  font-size: 1rem;
  font-weight: bold;
}
.dialog_style .btn.button-prev {
  background:#fff;
  color:#E2333E !important;
}
.dialog_style :not(ul.v-application){
  padding-left: 0 !important;
}
.dialog_style .header_item {
  margin: 0 0 0 auto;
}
.dialog_style .check {
  margin-left: 30%;
}

/deep/ .dialog_style .slick-dots {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  background-color: white;
}
</style>